/**
 * toggleActiveElement
 * Toggles "active" class on one item between many of the same
 * (like one li of multiples li's in an ul)
 */
const toggleActiveElement = element => {
  element.parentNode.childNodes.forEach((child) => {
    if (child instanceof HTMLElement) {
      child.classList.remove('active')
    }
  })

  element.classList.add('active')
}

/**
 * Check if given element is visible by user
 *
 * @param {HTMLElement} el
 */
const isVisible = el => el.clientHeight > 0 && el.clientWidth > 0

/**
 * Shrink text between parentheses on totals summary
 *
 * @param {string} selector Elements selector to be used on querySelectorAll
 */
const shrinkTextBetweenBrackets = selector => {
  const elements = document.querySelectorAll(selector)

  if (!elements.length) {
    return
  }

  elements.forEach(element => {
    const originalText = element.innerHTML.split('(')

    if (originalText.length <= 1) {
      return
    }

    originalText[2] = originalText[2] ? `(${originalText[2]}` : ''
    const smallText = `<div><small>(${originalText[1]}${originalText[2]}</small></div>`

    element.innerHTML = originalText[0] + smallText
  })
}

window.shrinkTextBetweenBrackets = shrinkTextBetweenBrackets

/**
 * Add an PHP-like isset() function
 * @param {any} value Value to test
 */
const isset = value => typeof value !== 'undefined'

window.isset = isset

/**
 * Build an list of inputs with given name across different contexts
 * @param {String} name Basic field name
 * @return {String} List of selectors list to use with querySelectorAll or jQuery
 */
const buildFormInputSelectors = name => `input#${name}, input#billing\\:${name}, input#shipping\\:${name}`

/**
 * Mask "taxvat" field as physical people
 */
const maskTaxvatPhysical = () => jQuery(buildFormInputSelectors('taxvat')).mask('000.000.000-00', { placeholder: '___.___.___-__' })

window.maskTaxvatPhysical = maskTaxvatPhysical

/**
 * Mask "taxvat" field as legal people
 */
const maskTaxvatLegal = () => jQuery(buildFormInputSelectors('taxvat')).mask('00.000.000/0000-00', { placeholder: '__.___.___/____-__' })

window.maskTaxvatLegal = maskTaxvatLegal

/**
 * Add live validation in form fields
 */
const initAsynchronousValidation = () => {
  const formFields = [...document.querySelectorAll('form input, form select, form textarea')]

  formFields.forEach(field => {
    field.addEventListener('blur', () => window.Validation.validate(field))
  })
}

/**
 * Submit given form asynchronously
 * @param {HTMLElement} form
 * @param {CallableFunction} callback
 */
window.submitFormAsync = (form, callback = null) => {
  form.classList.add('loading')
  const submitButtonIcon = form.querySelector('button[type="submit"] .fa')
  let originalIcon = ''

  if (submitButtonIcon) {
    originalIcon = submitButtonIcon.classList.toString()
    submitButtonIcon.classList = ''
    submitButtonIcon.classList.add('fa', 'fa-spin', 'fa-circle-o-notch')
  }

  const settings = {
    url: form.action,
    type: form.method
  }

  if (settings.type.toUpperCase() === 'POST') {
    const formData = new FormData(form)
    formData.append('form_key', window.form_key)
    settings.data = formData
    settings.processData = false
    settings.contentType = false
  }

  jQuery.ajax(settings).always((responseHtml, status) => {
    const responseWrapper = document.createElement('div')
    responseWrapper.innerHTML = responseHtml

    const messages = responseWrapper.querySelector('.messages')
    if (messages) {
      const oldMessages = form.querySelector('.messages')
      if (oldMessages) form.removeChild(oldMessages)
      form.appendChild(messages)
      setTimeout(() => jQuery(messages).slideDown(), 300)
      setTimeout(() => jQuery(messages).slideUp(), 5000)
    }

    if (submitButtonIcon) submitButtonIcon.classList = originalIcon
    form.classList.remove('loading')
    if (callback) callback(responseHtml, status)
  })

  return false
}

/**
 * Auto fill address form based on given zipcode
 * @param  {string} zipcode
 * @param  {object} fields  an object like that: {street: 'inputId', neighborhood: 'inputId', complement: 'inputId', city: 'inputId', state: 'selectId'}
 */
const autoFillAddress = (zipcode, fields) => {
  const states = {
    'AC': 485, 'AL': 486, 'AP': 487, 'AM': 488, 'BA': 489, 'CE': 490, 'ES': 491, 'GO': 492, 'MA': 493, 'MT': 494, 'MS': 495, 'MG': 496, 'PA': 497, 'PB': 498, 'PR': 499, 'PE': 500, 'PI': 501, 'RJ': 502, 'RN': 503, 'RS': 504, 'RO': 505, 'RR': 506, 'SC': 507, 'SP': 508, 'SE': 509, 'TO': 510, 'DF': 511
  }

  jQuery.get(`https://buscacep.e-flips.com.br/${zipcode.replace(/\D/, '')}`)
    .done(response => {
      const commonFields = ['street', 'complement', 'neighborhood', 'city', 'state']

      commonFields.forEach(field => {
        const input = fields[field] !== 'undefined' ? document.getElementById(fields[field]) : false
        if (input) {
          input.value = response[field]
        }
      })

      if (fields.state !== 'undefined' && document.getElementById(fields.state)) {
        document.getElementById(fields.state).value = states[response.state] - 484
      }
    })
}

/**
 * Initialize common input masks
 */
const maskFormFields = () => {
  jQuery(buildFormInputSelectors('postcode')).mask('00000-000', { placeholder: '_____-___' })
  jQuery(buildFormInputSelectors('telephone')).mask('(00) 00000-0000', { placeholder: '(__) _____-____' })
  jQuery(buildFormInputSelectors('fax')).mask('(00) 0000-0000', { placeholder: '(__)____-____' })

  if (!jQuery('.customer-account-edit')) {
    maskTaxvatPhysical()
  }
}

/**
 * Add some validate-* classes to common fields
 */
const addCommonValidationClasses = () => {
  jQuery(buildFormInputSelectors('postcode')).addClass('validate-postcode')
  jQuery(buildFormInputSelectors('telephone')).addClass('validate-phone')
  jQuery(buildFormInputSelectors('fax')).addClass('validate-phone')
  jQuery(buildFormInputSelectors('taxvat')).addClass('validate-taxvat')
}

jQuery(() => {
  document.querySelectorAll('#nav > li').forEach(item => {
    if (item.querySelector('ul')) {
      const icon = document.createElement('i')
      icon.classList.add('fa')
      icon.classList.add('fa-angle-down')

      item.querySelector('a').insertBefore(icon, item.querySelector('a span'))
    }
  })

  window.addEventListener('scroll', () => {
    const header = document.querySelector('header')

    if (document.querySelector('html').scrollTop > 50 || document.querySelector('body').scrollTop > 50) {
      return header.classList.add('header--scrolled')
    }

    return header.classList.remove('header--scrolled')
  })

  // Start default product carosuel(s)
  jQuery('.category-products--carousel ul').slick({
    vertical: false,
    verticalSwiping: false,
    autoplay: true,
    slidesToShow: 1,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 4 } },
      { breakpoint: 767, settings: { slidesToShow: 2 } },
      { breakpoint: 0, settings: { slidesToShow: 1 } }],
    autoplaySpeed: 5000,
    mobileFirst: true,
    prevArrow: '<div class="slick-prev"><i class="fa fa-angle-left" aria-hidden="true" ></i></div>',
    nextArrow: '<div class="slick-next"><i class="fa fa-angle-right" aria-hidden="true"></i></div>'
  })

  // Toggle currently visible tab
  document.querySelectorAll('.tabs label').forEach((tab) => {
    tab.addEventListener('click', function () {
      const target = this.dataset.target
      toggleActiveElement(this)
      toggleActiveElement(document.getElementById(target))
    })
  })

  window.addEventListener('DOMContentLoaded', maskFormFields)
  window.addEventListener('DOMContentLoaded', addCommonValidationClasses)
  window.addEventListener('DOMContentLoaded', initAsynchronousValidation)
})
